@use "ics-theme" as ics;

@use "@ics-angular/messenger" as msg;

@use "styles/global" as global;

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

* {
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

/*
Removing arrows from number inputs
*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

table {
  width: 100%;

  tr {
    max-width: 10%;
  }
}

// aligning all card actions in the same manner
.mat-mdc-card-actions {
  gap: 20px !important;
}

.dark-theme {
  .mdc-tab--active {
    .mdc-tab__text-label {
      color: whitesmoke !important;
    }
  }
}


