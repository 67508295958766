.row {
  display: flex;
  flex-flow: row;
}

.column {
  display: flex;
  flex-flow: column;
}

.wrap {
  flex-wrap: wrap;
}

.center-alignment {
  align-items: center;
  align-content: center;
}

.baseline-alignment {
  align-items: baseline;
  align-content: baseline;
}

.justify-center {
  justify-content: center;
  justify-items: center;
}

.center-justify {
  justify-items: center;
  justify-content: center;
}
