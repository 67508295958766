$roboto-font-path: "../../assets/fonts/roboto";

/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"),
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300.woff") format("woff"),
    /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-300italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300italic.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"),
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300italic.woff2") format("woff2"),
    /* Super Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300italic.woff") format("woff"),
    /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-300italic.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"),
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-regular.woff") format("woff"),
    /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
  src: local("Roboto Italic"), local("Roboto-Italic"),
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-italic.woff") format("woff"),
    /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-italic.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"),
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500.woff") format("woff"),
    /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-500italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500italic.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"),
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500italic.woff") format("woff"),
    /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-500italic.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700.eot"); /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"),
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700.woff") format("woff"),
    /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* roboto-700italic - latin-ext_latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700italic.eot"); /* IE9 Compat Modes */
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700italic.woff") format("woff"),
    /* Modern Browsers */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700italic.ttf") format("truetype"),
    /* Safari, Android, iOS */
  url("#{$roboto-font-path}/roboto-v20-latin-ext_latin-700italic.svg#Roboto") format("svg"); /* Legacy iOS */
}
