.theme-colored {
  color: white;

  * {
    color: white;
  }
}

body[class*="ics-light-grey-"], .ics-light-grey-mid-green {
  background-color: rebeccapurple !important;

  .theme-colored {
    color: black !important;

    * {
      color: black !important;
    }
  }
}

.primary-background {
  background-color: var(--ics-primary-color) !important;
}

.accent-background {
  background-color: var(--ics-accent-color) !important;
}

.warn-background {
  background-color: var(--ics-warn-color) !important;
}
