.spacing {
  display: flex;
  gap: 2rem;
}

.small-spacing {
  display: flex;
  gap: 1rem;
}

/// shifts the elements on it left and right to the flexes end
.separator {
  flex: 1 1 auto;
}
