/// set max width of element and displays overflow with an ellipsis (...)
.ellipsed-overflow {
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.spacer {
  height: 2rem;
}

.spacer.small {
  margin: 0;
  height: 0.02%;
}

.vertical-spacer {
  width: 2rem;
}

.vertical-spacer.small {
  width: 0.2rem;
}

.default-spacing {
  display: flex;
  gap: 2rem;
}

.center-height {
  align-items: center;
  align-self: center;
}

.baseline-alignment {
  align-items: baseline;
  align-content: baseline;
}

/// creates an element that floats at the bottom left corner
.floating-btn-bar {
  position: fixed;
  bottom: 8%;
  right: 3%;

  z-index: 10;
}

/// creates an simple row layout
.floating-display-control {
  display: flex;
  flex-flow: row;
  align-items: center;
  align-content: center;
}

/// sticks the paginator to the mat-table
.mat-paginator-sticky {
  bottom: 0;
  position: sticky !important;
  z-index: 10;
}

/// creates the default appearence of an filter in the app
.filter {
  margin-top: 1%;
}

/// default behavior of an table
.table-surrounding {
  height: 50%;
  max-height: calc(100vh / 1.6);
  overflow: auto;
  margin-top: 1%;
}






